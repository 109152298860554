import _ from 'lodash';
import React from 'react';
import { Container, Dropdown, Grid, Header, Segment, Button } from 'semantic-ui-react';
import { withNamespaces, Link, i18n } from '../i18n';
import ApiItem from '../components/api_item';
import Head from 'next/head';
import { GET_APIS_URL } from '../constants/constants';
import { SsrResponsive } from '../lib/ssr';
import Text, { TextParser } from '../components/Text';
import { ssrFetch } from '../lib/rest';

const DropDownFilter = ({ categories, handleChange, displayCategory, allText }) => {
	const options = categories.map((value, i) => ({ key: i + 1, text: value, value }));
	options.unshift({ key: 0, text: allText, value: '' });

	return <Dropdown scrolling text={displayCategory || allText} icon="filter" labeled button className="icon" value={displayCategory} onChange={handleChange} options={options} />;
};

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: false,
			displayCategory: props.name,
			menuFixed: false,
			overlayFixed: false,
			categories: this.filter(),
		};

		this.setCategory = this.setCategory.bind(this);
		this.state.displayCategory = props.name;
	}

	filter() {
		const { i18n } = this.props;
		let categories = _.map(this.props.list, item => (!!item.category.en ? item.category[i18n.language] : item.category)).filter((x, i, a) => a.indexOf(x) === i);
		categories.sort();
		this.lang = this.props.i18n.language;
		if (this.state) this.setState({ categories });
		else return categories;
		this.setState({ displayCategory: '' });
	}

	componentDidUpdate() {
		if (this.lang !== this.props.i18n.language) this.filter();
	}

	static async getInitialProps({ req, query }) {
		let { name } = query;

		const result = await ssrFetch(req, GET_APIS_URL).then(res => res.json());

		return { list: result.result, namespacesRequired: ['common'], name: name };
	}

	setCategory(category) {
		this.setState({ displayCategory: category });
	}

	handleChange = (e, { value }) => this.setState({ displayCategory: value });

	render() {
		const { displayCategory, categories = [], contextRef } = this.state;
		const { t, list = [], tagOptions = [] } = this.props;
		const allText = t('all');
		const { i18n } = this.props;
		return (
			<div>
				{displayCategory ? (
					<Head>
						<title>{displayCategory} {t('categoryTabtitlenew')} CollectAPI </title>
					</Head>
				) : (
					''
				)}
				<Container>
					<SsrResponsive maxWidth={SsrResponsive.onlyMobile.maxWidth}>
						<DropDownFilter {...{ categories, handleChange: this.handleChange, displayCategory, allText }} />
					</SsrResponsive>
					<Grid style={{ marginTop: 0 }} stackable>
						<Grid.Column tablet={6} computer={4} only="tablet computer">
							<Header as="h4" content={t('categories')} textAlign="center" />
							<Button.Group fluid vertical basic style={{ background: '#fff' }}>
								<Link href="/" passHref>
									<Button style={{ fontFamily: 'inherit', textAlign: 'left' }} active={!displayCategory} onClick={() => this.setCategory('')}>
										{allText}
									</Button>
								</Link>
								<Link key={'free-apis'} as="/categories/free-apis" href={'/name=free-apis'} passHref>
									<Button style={{ fontFamily: 'inherit', textAlign: 'left' }} active={displayCategory === 'free'} onClick={() => this.setCategory('free')}>
										{t('freeAvaible')}
									</Button>
								</Link>
								{categories.map(category => (
									<Link key={category} as={'/categories/' + _.kebabCase(category)} href={'/?name=' + category} passHref>
										<Button style={{ fontFamily: 'inherit', textAlign: 'left' }} active={displayCategory === category} key={category} onClick={() => this.setCategory(category)}>
											<Text text={category} />
										</Button>
									</Link>
								))}
							</Button.Group>
						</Grid.Column>
						<Grid.Column mobile={16} tablet={10} computer={12}>
							<div style={{ marginTop: '1.2rem' }}>
								{displayCategory == 'free' || displayCategory == 'free-apis'
									? _.map(list, (item, i) => {
											if (item && item.free) {
												return (
													<Grid.Column key={item.name + i} style={{ marginBottom: '1rem' }}>
														<Segment attached padded style={{ overflowY: 'auto' }}>
															<ApiItem item={item} />
														</Segment>
													</Grid.Column>
												);
											}
									  })
									: _.map(list, (item, i) => {
											if (item && (_.kebabCase(!!item.category.en ? item.category[i18n.language] : item.category) === _.kebabCase(displayCategory) || !displayCategory)) {
												return (
													<Grid.Column key={item.name + i} style={{ marginBottom: '1rem' }}>
														<Segment attached padded style={{ overflowY: 'auto' }}>
															<ApiItem item={item} />
														</Segment>
													</Grid.Column>
												);
											}
									  })}
							</div>
						</Grid.Column>
					</Grid>
				</Container>
			</div>
		);
	}
}

export default withNamespaces()(Home);
