import React from 'react';
import { i18n, withNamespaces } from '../i18n';
import _ from 'lodash';

export const TextParser = (data, _i18n) => {
	if (_.isString(data)) return data;
	let lang = i18n.language || (_i18n && _i18n.language) || 'en';
	let text = data && (data[lang] || data['en']);
	return text || '----missing----';
};

class Text extends React.Component {
	render() {
		let render = this.props.render || (_.isFunction(this.props.children) && this.props.children);
		if (render) return render(data => TextParser(data, this.props.i18n));
		return <>{TextParser(this.props.children || this.props.text, this.props.i18n)}</>;
	}
}

export default withNamespaces()(Text);
